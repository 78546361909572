/* @font-face {
    font-family: 'reey';
    src: url('/assets/fonts/reey-regular-webfont.woff2') format('woff2'), url('/assets/fonts/reey-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply inline-block rounded-2xl bg-primary px-7 py-4 text-center text-sm font-extrabold uppercase text-black transition  hover:scale-105 hover:bg-primary/80;
    }
    .btn-small {
        @apply inline-block rounded-lg bg-primary px-6 py-2 text-center text-sm font-extrabold uppercase text-black transition hover:scale-105  hover:bg-primary/80 md:px-4;
    }
    .heading {
        @apply mb-10 lg:mb-14;
    }
    .heading h2 {
        @apply mb-3 text-lg font-extrabold text-primary sm:mb-4;
    }
    .heading h1 {
        @apply text-2xl font-extrabold text-black dark:text-white sm:text-3xl lg:text-[40px] lg:!leading-[50px];
    }

    /* .heading h6 {
        @apply mb-2 text-lg font-extrabold text-primary sm:mb-3;
    }
    .heading h4 {
        @apply text-xl font-extrabold text-black dark:text-white sm:text-2xl lg:!leading-[50px];
    } */

    .heading h6 {
        @apply mb-3 text-lg font-extrabold text-primary sm:mb-4;
    }
    .heading h5 {
        @apply mb-3 text-xl font-extrabold  sm:mb-4 sm:text-2xl;
    }
    .heading h4 {
        @apply text-2xl font-extrabold text-black dark:text-white sm:text-3xl lg:text-[40px] lg:!leading-[50px];
    }

    .portfolio-filter .filter.active button,
    .upcoming-party-filter .filter.active button {
        @apply bg-secondary text-white;
    }
    .upcoming-party-filter .filter.active button span {
        @apply text-white;
    }
    .home-filter .filter.active button {
        @apply text-secondary;
    }
    .realestate-filter .filter.active button {
        @apply scale-105 bg-primary text-white;
    }

    .photography-filter .filter.active button {
        @apply bg-secondary text-white;
    }

    /* NFT Landing Page CSS */
    .nft-filters li.active button {
        @apply bg-black text-white dark:bg-white dark:text-black;
    }
    .heading-gradiant {
        @apply mb-12;
    }
    .heading-gradiant h4 {
        @apply relative z-[1] text-3xl font-black uppercase leading-[50px] text-black before:absolute before:top-5 before:z-[-1] before:h-6
        before:w-[100px] before:bg-primary/20 dark:text-white md:text-[40px] md:leading-[60px] md:before:h-[30px] ltr:pl-0.5 ltr:before:left-0 ltr:before:right-auto rtl:pr-0.5 rtl:before:left-auto rtl:before:right-0;
    }
    .heading-gradiant h4 span {
        @apply bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent;
    }
    .heading-gradiant p {
        @apply mt-3 font-bold;
    }
    /* --------- NFT - END -------- */

    /* Filter */

    .project.hidden {
        animation: hide 1s ease 0s 1 normal forwards;
        transform-origin: center;
    }
    .project.block {
        animation: show 1s ease 0s 1 normal forwards;
        transform-origin: center;
    }

    @keyframes hide {
        0% {
            transform: scale(1);
            visibility: visible;
        }
        100% {
            transform: scale(0);
            visibility: hidden;
        }
    }
    @keyframes show {
        0% {
            transform: scale(0);
            visibility: hidden;
        }
        100% {
            transform: scale(1);
            visibility: visible;
        }
    }

    /* Light - Dark Mode */

    .dark .light-mode-btn {
        @apply block;
    }
    .dark .dark-mode-btn {
        @apply hidden;
    }

    /* Header */

    header .menus {
        @apply fixed top-0 z-[52] h-screen w-full max-w-sm gap-9 bg-white transition-all duration-500 dark:bg-gray-500 lg:static lg:h-auto lg:max-w-none lg:bg-transparent dark:lg:bg-transparent ltr:-right-full rtl:-left-full;
    }
    header .menus > ul {
        @apply relative flex flex-col divide-y font-bold text-black lg:flex-row lg:items-center lg:gap-4 lg:divide-y-0 lg:p-0;
    }
    header .menus > ul li > div {
        @apply px-4 py-4 transition lg:px-0 lg:py-4;
    }
    header .menus > ul li > a {
        @apply flex items-center justify-between whitespace-nowrap px-4 py-4 text-sm font-semibold text-black transition hover:border-b-4 hover:border-primary hover:text-primary lg:px-0 lg:py-4 hover:lg:scale-110;
    }
    header .menus > ul li > a.active {
        @apply text-primary lg:scale-110;
    }
    header .menus .submenu {
        @apply invisible z-50 mt-5 hidden grid-cols-1 gap-x-4 rounded-lg py-3 text-sm font-semibold text-black opacity-0 transition-all dark:text-white lg:absolute lg:top-full lg:grid lg:min-w-max lg:grid-cols-3 lg:bg-[#F0F4F7] lg:shadow-lg lg:dark:bg-gray-500 xl:grid-cols-4 ltr:lg:-translate-x-1/4 ltr:xl:-left-1/2 rtl:lg:translate-x-1/4 rtl:xl:-right-1/2;
    }
    .submenu {
        @apply group-hover:visible group-hover:mt-0 group-hover:grid group-hover:opacity-100;
    }
    header .menus .submenu a {
        @apply block px-8 py-2.5 transition lg:px-5 hover:lg:scale-105;
    }
    header .menus .submenu a.active {
        @apply text-secondary lg:scale-105;
    }

    .text-stroke {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #222a36;
    }

    /* Creative Agency Page CSS */
    .text-color {
        @apply bg-[url(/assets/images/creative/color.png)] bg-clip-text bg-top bg-no-repeat text-transparent;
    }
}

/* App*/
/* This assumes no margin or padding on #app's parent(s) */
#app {
    height: 100vh;
    max-height: 100dvh;
    overscroll-behavior: none;
    -webkit-font-smoothing: antialiased;
    overflow: auto;
}

/* responsive menu */

header .menus.open-menus {
    @apply overflow-y-auto ltr:right-0 rtl:left-0;
}

/* sticky header */

.sticky-header {
    @apply bg-black shadow-lg;
}

/* lightbox */

.fancybox__content > .carousel__button.is-close {
    @apply rtl:left-0;
}

/* accordion */

.ac.is-active .ac-trigger .trigger-icon {
    @apply !border-black !text-black dark:!border-white dark:!text-white;
}

.ac.is-active .ac-trigger .trigger-icon svg path {
    @apply first:hidden;
}
.open-search-bar {
    @apply w-full;
}

/* apex chart */

.dark .apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light,
.dark .apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light {
    box-shadow: none;
    @apply border-[#050717cc] bg-[#050717cc] text-white;
}

.dark .apexcharts-canvas .apexcharts-xaxistooltip-bottom:before,
.dark .apexcharts-canvas .apexcharts-xaxistooltip-bottom:after {
    @apply border-b-[#050717cc];
}

.dark .apexcharts-canvas .apexcharts-tooltip-series-group.apexcharts-active {
    @apply text-white;
}

.dark .apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    @apply border-[#060818] bg-[#060818];
}

.dark .apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light {
    box-shadow: none;
}

[dir='rtl'] .apexcharts-tooltip-marker {
    @apply ml-2.5 mr-0;
}
/* Hotel & resort */
.book-form input[type='date']::-webkit-calendar-picker-indicator {
    @apply invert;
}

/* Photography our Team */

.our-team .swiper-wrapper {
    @apply items-center;
}

.our-team .swiper-slide img {
    @apply h-[390px];
}

.our-team .swiper-slide.swiper-slide-prev img,
.our-team .swiper-slide.swiper-slide-next img {
    @apply h-[478px];
}

.our-team .swiper-slide.swiper-slide-active img {
    @apply h-[566px];
}

@media screen and (max-width: 1200px) {
    .our-team .swiper-slide img {
        @apply h-[300px];
    }

    .our-team .swiper-slide.swiper-slide-prev img,
    .our-team .swiper-slide.swiper-slide-next img {
        @apply h-[400px];
    }

    .our-team .swiper-slide.swiper-slide-active img {
        @apply h-[500px];
    }
}

@media screen and (max-width: 767px) {
    .our-team .swiper-slide img {
        @apply !h-[300px];
    }
}

.text-muted-foreground {
    color: #6b7280; /* Adjust the color as needed */
}

/* .lucide {
    color: #1464b3;
} */


@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 3.9%;
        --primary: 210 100% 35%;
        --primary-foreground: 0 0% 100%;
        --secondary: 199 66% 58%;
        --secondary-foreground: 0 0% 100%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --accent: 84 54% 52%;
        --accent-foreground: 0 0% 100%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 210 100% 35%;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
        --radius: 0.5rem;

        /* Secondary colors */
        --light-blue: 193 48% 79%;
        --purple: 248 69% 72%;
        --dark-blue: 206 22% 37%;
    }
    .dark {
        --background: 0 0% 3.9%;
        --foreground: 0 0% 98%;
        --card: 0 0% 3.9%;
        --card-foreground: 0 0% 98%;
        --popover: 0 0% 3.9%;
        --popover-foreground: 0 0% 98%;
        --primary: 0 0% 98%;
        --primary-foreground: 0 0% 9%;
        --secondary: 0 0% 14.9%;
        --secondary-foreground: 0 0% 98%;
        --muted: 0 0% 14.9%;
        --muted-foreground: 0 0% 63.9%;
        --accent: 0 0% 14.9%;
        --accent-foreground: 0 0% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;
        --border: 0 0% 14.9%;
        --input: 0 0% 14.9%;
        --ring: 0 0% 83.1%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

body {
    font-family: var(--font-poppins), sans-serif;
}

.badge-glow {
    animation: glow 2s infinite alternate;
}

@keyframes glow {
    from {
        box-shadow: 0 0 5px rgba(0, 102, 204, 0.2);
    }
    to {
        box-shadow: 0 0 15px rgba(0, 102, 204, 0.6);
    }
}

@layer utilities {
    .text-balance {
      text-wrap: balance;
    }
  
    .scrollbar-hide {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  
    .scrollbar-hide::-webkit-scrollbar {
      display: none;
    }
  }

.dynamic-bg {
    background: linear-gradient(45deg, hsl(var(--primary)), hsl(var(--secondary)), hsl(var(--accent)));
    background-size: 200% 200%;
    animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.neon-glow {
    box-shadow: 0 0 5px hsl(var(--accent)), 0 0 10px hsl(var(--accent)), 0 0 15px hsl(var(--accent));
    animation: neon-pulse 1.5s ease-in-out infinite alternate;
}

@keyframes neon-pulse {
    from {
        box-shadow: 0 0 5px hsl(var(--accent)), 0 0 10px hsl(var(--accent)), 0 0 15px hsl(var(--accent));
    }
    to {
        box-shadow: 0 0 10px hsl(var(--accent)), 0 0 20px hsl(var(--accent)), 0 0 30px hsl(var(--accent));
    }
}

/* Add these new badge highlight styles */
.badge-highlight {
    box-shadow: 0 0 15px rgba(0, 102, 204, 0.6), 0 0 30px rgba(0, 102, 204, 0.3);
    animation: badge-pulse 2s infinite alternate;
  }
  
  @keyframes badge-pulse {
    0% {
      box-shadow: 0 0 15px rgba(0, 102, 204, 0.6), 0 0 30px rgba(0, 102, 204, 0.3);
      transform: scale(1);
    }
    100% {
      box-shadow: 0 0 25px rgba(0, 102, 204, 0.8), 0 0 40px rgba(0, 102, 204, 0.5);
      transform: scale(1.05);
    }
  }
  
  /* Enhance the badge glow effect */
  .badge-glow {
    animation: glow 2s infinite alternate;
    transition: transform 0.3s ease;
  }
  
  .badge-glow:hover {
    transform: scale(1.05);
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 5px rgba(0, 102, 204, 0.2);
    }
    to {
      box-shadow: 0 0 15px rgba(0, 102, 204, 0.6), 0 0 20px rgba(0, 102, 204, 0.3);
    }
  }
  
  /* Add a subtle pulse animation for the badge background */
  @keyframes subtle-pulse {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 0.4;
    }
  }
  
  .badge-bg-pulse {
    animation: subtle-pulse 3s infinite ease-in-out;
  }
  
  .dynamic-bg {
    background: linear-gradient(45deg, hsl(var(--primary)), hsl(var(--secondary)), hsl(var(--dark-blue)));
    background-size: 200% 200%;
    animation: gradientBG 15s ease infinite;
  }
  
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* Remove or comment out this section
  .neon-glow {
    box-shadow: 0 0 5px hsl(var(--accent)), 0 0 10px hsl(var(--accent)), 0 0 15px hsl(var(--accent));
    animation: neon-pulse 1.5s ease-in-out infinite alternate;
  }
  
  @keyframes neon-pulse {
    from {
      box-shadow: 0 0 5px hsl(var(--accent)), 0 0 10px hsl(var(--accent)), 0 0 15px hsl(var(--accent));
    }
    to {
      box-shadow: 0 0 10px hsl(var(--accent)), 0 0 20px hsl(var(--accent)), 0 0 30px hsl(var(--accent));
    }
  }
  */
  
  /* Add these animation classes to the end of your globals.css file */
  @keyframes spin-slow {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin-slow-reverse {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
  
  .animate-spin-slow {
    animation: spin-slow 30s linear infinite;
  }
  
  .animate-spin-slow-reverse {
    animation: spin-slow-reverse 20s linear infinite;
  }

  @media (max-width: 768px) {
    .dynamic-bg {
      background-size: 300% 300%;
    }
  
    .container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  
    h1,
    h2,
    h3 {
      word-break: break-word;
    }
  }
